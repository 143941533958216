import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CSSLoader from "./CSSLoader";

const BalanceItems = (props) => {
    const [items, setItems] = useState(props.initData);
    const [page, setPage] = useState(2);

    const formatCurrency = (m, r) => {
        let className = 'text-large';
        if (r === '') {
            className += ` text-${m < 0 ? 'red' : 'green'}`;
        }
        m = Math.abs(m).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
        return `<div class="${className}">${m}</div>`;
    }

    const updateBalance = (balance) => {
        document.querySelector('#balance').innerHTML = balance;
    }

    const fetchMoreData = () => {
        setPage(page + 1);

        fetch('/data/', {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"action": "getlist", "page": page, "token": props.token, "pagesize": props.pageSize})
        })
        .then(resp => resp.json())
        .then(data => {
            const respAry = [];
            if (data.data !== null) {
                const rawData = data.data;
                if (rawData.token === props.token) {
                    updateBalance(rawData.balance);
                    Object.keys(rawData.items).forEach(key => {
                        respAry.push(rawData.items[key]);
                    });
                    setItems(items.concat(respAry));
                }
            }
        });
    }

    const refresh = () => {
        document.querySelector('.loader-wrapper').style.display = 'flex';
        setTimeout(() => {
            fetch('/data/', {
                method: 'POST',
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"action": "getlist", "page": 1, "token": props.token, "pagesize": props.pageSize})
            })
            .then(resp => resp.json())
            .then(data => {
                const respAry = [];
                if (data.data !== null) {
                    const rawData = data.data;
                    if (rawData.token === props.token) {
                        document.querySelector('.loader-wrapper').style.display = 'none';
                        updateBalance(rawData.balance);
                        Object.keys(rawData.items).forEach(key => {
                            respAry.push(rawData.items[key]);
                        });
                        setItems(respAry);
                        setPage(2);
                    }
                }
            });
        }, 250);
    }    

    const editItem = id => {
        fetch('/edit/', {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"action": "edit", "id": id, "token": props.token})
        })
        .then(resp => resp.text())
        .then(data => {
            document.getElementById('editPanelHost').innerHTML = data;
            localStorage.setItem(props.token, 'false');
            window['appTimer'] = setInterval(() => {
                if (localStorage.getItem(props.token) === 'true') {
                    clearInterval(window['appTimer']);
                    refresh();
                }
            }, 500);
            setTimeout(() => {
                document.querySelector('.edit-panel').style.top = '0px';
            }, 50);
        });
    }

    const scrollToTop = () => {
        document.querySelector('.scrollableDiv').scrollTo(0, 0);
    }

    return (
        <>
            <div className="header" onClick={scrollToTop}>
                <div className="text-align-left text-xlarge text-white width-50">Balance</div>
                <div id="balance" className="text-align-right text-xlarge text-white width-50">{props.balance}</div>
            </div>
            <CSSLoader/>
            <div id="scrollableDiv" className="scrollableDiv">
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={props.totalRecord - ((page - 1) * props.pageSize) > 0}
                    scrollableTarget="scrollableDiv"
                    refreshFunction={refresh}
                    pullDownToRefresh={true}
                    pullDownToRefreshThreshold={50}
                >
                {items.map(item => (
                    <>
                        <div key={`item${item.id}}`} className="list-item" onClick={() => editItem(item.id)}>
                            <div className="text-align-left">
                                <div className="text-large">{item.date}</div>
                                <div className="text-gray">{item.cardname}</div>
                            </div>
                            <div className="text-align-right">
                                <div dangerouslySetInnerHTML={{__html: formatCurrency(item.amount, item.resource)}}></div>
                                <div className="text-gray">{item.notes}</div>
                            </div>
                        </div>
                    </>
                ))}
                </InfiniteScroll>
                <div className="bottomSpacer"></div>
            </div>
            <div className="footer"></div>
            <div className="addButton" onClick={() => editItem(0)}>+</div>
            <div id="editPanelHost"></div>
        </>
    );
}

export default BalanceItems;