import { useState, useRef } from 'react';
import DigitButton from './components/DigitButton';
import BalanceItems from './components/BalanceItems';
import './App.css';

const App = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const iPhone = navigator.userAgent.match(/iphone/i) !== null;
    const appToken = useRef('');
    const balance = useRef(0);
    const totalRecord = useRef(0);
    const initData = useRef([]);
    const pageSize = 50;
    let passkey = '';

    const updateDisplay = () => {
        let code = '';
        let n = passkey.length;
        while (n > 0) {
            code += '<div class="dot"></div>';
            n--;
        }
        document.querySelector('.accesskeyDisplay').innerHTML = code;
        document.querySelector('.functionBar').style.display = passkey.length === 0 ? 'none' : 'block';
    }

    const deleteClicked = e => {
        if (passkey.length > 0) {
            passkey = passkey.slice(0, passkey.length - 1);
            updateDisplay();
        }
    }

    const resetClicked = e => {
        passkey = '';
        updateDisplay();
    }

    const createToken = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        let counter = 0;
        while (counter < 32) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
          counter += 1;
        }
        return result;
    }

    const digitClicked = e => {
        if (passkey.length >= 16) return;
        passkey += e.target.innerHTML;
        updateDisplay();
        const token = createToken();

        fetch('/doorman/', {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"passkey": passkey, "token": token, "pagesize": pageSize})
        })
        .then(resp => resp.json())
        .then(data => {
            const respAry = [];
            if (data.data !== null) {
                const rawData = data.data;
                if (rawData.token === token) {
                    appToken.current = rawData.token;
                    balance.current = rawData.balance;
                    totalRecord.current = rawData.rows;
                    Object.keys(rawData.items).forEach(key => {
                        respAry.push(rawData.items[key]);
                    });
                    initData.current = respAry;
                    setLoggedIn(true);
                }
            }
        });
    }

    return (
        <>
            {iPhone ? 
            <>
                {loggedIn ? 
                <>
                    <BalanceItems
                        token={appToken.current}
                        balance={balance.current}
                        totalRecord={totalRecord.current}
                        initData={initData.current}
                        pageSize={pageSize}
                        setLoggedIn={setLoggedIn}
                    />
                </>
                :
                <>
                    <div className="loginPanel">
                        <div>
                            <div className="accesskeyDisplay"></div>
                            <div>
                                <div className="digitKeys">
                                    <DigitButton digit="1" func={digitClicked}/>
                                    <DigitButton digit="2" func={digitClicked}/>
                                    <DigitButton digit="3" func={digitClicked}/>
                                </div>
                                <div className="digitKeys">
                                    <DigitButton digit="4" func={digitClicked}/>
                                    <DigitButton digit="5" func={digitClicked}/>
                                    <DigitButton digit="6" func={digitClicked}/>
                                </div>
                                <div className="digitKeys">
                                    <DigitButton digit="7" func={digitClicked}/>
                                    <DigitButton digit="8" func={digitClicked}/>
                                    <DigitButton digit="9" func={digitClicked}/>
                                </div>
                                <div className="digitKeys">
                                    <DigitButton digit="0" func={digitClicked}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="functionBar">
                        <div className="del" onClick={deleteClicked}>Delete</div>
                        <div className="reset" onClick={resetClicked}>Reset</div>
                    </div>
                </>
                }
            </>
            :
            <></>
            }
        </>
    );
}

export default App;