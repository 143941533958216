import '../CSSLoader.css';

const CSSLoader = () => {
    return (
        <div className="loader-wrapper">
            <div className="loader">
                <div className="loadingio-spinner-spinner-387j3tfs6xq">
                    <div className="ldio-tret0lt7p9l">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CSSLoader;